<template>
  <allOrder :dataAuthority="true" pageType="history" :listType="6" />
</template>

<script>
import allOrder from '@/views/work-order/all-order'
export default {
  components: {
    allOrder
  }
}
</script>
